import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Link } from 'gatsby'

const GoretexPage = () => (
  <Layout>
    <SEO
      title="GoreTex"
      description="Read the case study of Gore-Tex Club, an MVP development project for Gore-Tex."
    />
    <section>
      <hgroup>
        <h6>2016</h6>
        <h2>Gore-Tex Club</h2>
      </hgroup>
      <div className="project-header-image">
        <Image filename="goretex-home" />
      </div>
      <div className="section-columns">
        <div>
          <h3>Overview</h3>
          <p>
            In summer 2016 I’ve been working on a freelance project for
            Gore-Tex. Gore-Tex started digital initiatives for lean startup
            projects and outlined possible ideas to look into in a short MVP
            request brief. One of the ideas described a garment wash and care
            help app for their customers, as most of them didn’t know how to
            take care of the different outdoor gear.
          </p>
          <p>
            As Gore-Tex is a huge supplier for hundreds of outdoor brands, they
            also didn’t have any real customer data and no direct channel to
            them.
          </p>
        </div>
        <div>
          <h3>Challenge</h3>
          <p>
            I had to come up with an idea of a gear care app, which actually is
            being used to provide data for Gore-Tex. Since Gore-Tex is used by
            many different outdoor brands, it also had to support an easy way of
            adding new gear regardless of the brand or shop. The process of
            adding new gear should be easy and fast for the customer. It was
            also a challenge to use the color scheme of the Gore-Tex brand.
          </p>
        </div>
      </div>
      <Image filename="goretex-scribble" />
      <div className="section-columns">
        <div>
          <h3>Approach</h3>
          <p>
            At the beginning of the project it was necessary to find outdoor
            customers to validate ideas, pains and needs. I held several
            interviews and approached them with different ideas. Most of them
            showed no interest in a garment care app without any extra value. To
            understand them better I created a hooked model. By introducing a
            loyalty program most customers got interested.
          </p>
          <p>
            This turned the idea of a garment care app to a gore-tex membership
            app with care instructions, but also with exclusive services,
            sponsored trips, gear suggestions and special discounts for new
            gear. I also created the customer journey for buying a new gear to
            find a solution for adding gear to the app easily.
          </p>
        </div>
        <div>
          <h3>Implementation</h3>
          <p>
            During the project I was responsible for the concept and ux/ui
            design process. I’ve created different prototypes for a first MVP
            from scribbles to wireframes and mockups. During the iterations I
            gathered feedback from customers and also from Gore-Tex as
            stakeholder. This helped to validate the ideas and also move forward
            with the MVP. To understand the user flows and get a better
            understanding of the app I also created a click dummy in Marvel.
          </p>
          <p>
            Furthermore I also explored the possibilities of the loyalty program
            and how the business model would look like.
          </p>
        </div>
      </div>

      <div className="section-columns col-4">
        <div>
          <Image filename="goretex-1" width="100%" />
          <p>Users can manage their gear.</p>
        </div>
        <div>
          <Image filename="goretex-2" width="100%" />
          <p>
            Users were able to also find new gear and buy it online from
            connected stores.
          </p>
        </div>
        <div>
          <Image filename="goretex-3" width="100%" />
          <p>For each gear, users see the cleaning and care instructions.</p>
        </div>
        <div>
          <Image filename="goretex-4" width="100%" />
          <p>
            By scanning a goretex tag from the garment, users were able to add
            newly bought gear to their list.
          </p>
        </div>
      </div>
    </section>
    <div className="cta-section">
      <section>
        <h2>Do you have a product vision?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to realise their
          vision in a market ready product. <br /> I would be happy to help you,
          developing your digital product from scratch and get it live.
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Tell me about your product
        </a>
      </section>
    </div>
    <section>
      <div className="similar">
        <div>
          <Link to="/project/kapilendo/">Read Kapilendo study</Link>
        </div>
        <div className="text-center">
          <Link to="/">Go to homepage</Link>
        </div>
        <div className="text-right">
          <Link to="/project/energis/">Read Energis study</Link>
        </div>
      </div>
    </section>
  </Layout>
)
export default GoretexPage
